import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {PaymentMethodCreateService} from "./payment-method-create.service";
import {PaymentMethod} from "../payment-methods.enum";
import {BehaviorSubject, of, Subscription} from "rxjs";
import {switchMap} from "rxjs/operators";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../../shared/services/user.service";
import {CreateCreditCardFormComponent} from "../create-credit-card-form/create-credit-card-form.component";
import {
  CreateCreditCardFormPayrixComponent
} from "../create-credit-card-form-payrix/create-credit-card-form-payrix.component";

@Component({
  selector: 'app-payment-method-create',
  templateUrl: './payment-method-create.component.html',
  styleUrls: ['./payment-method-create.component.scss'],
  providers: [
    {
      provide: PaymentMethodCreateService,
      useClass: PaymentMethodCreateService
    }
  ]
})
export class PaymentMethodCreateComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  @ViewChild('creditCardFormComponent') creditCardFormComponent?: CreateCreditCardFormComponent;
  @ViewChild('creditCardFormComponentPayrix') creditCardFormComponentPayrix?: CreateCreditCardFormPayrixComponent;

  @Input() isOnboarding = false;
  @Output() success = new EventEmitter<boolean>();

  userInfo = this.userService.me();
  step = 1;

  paymentMethod = new BehaviorSubject<PaymentMethod | null>(null);

  isCreditMethod = this.paymentMethod.pipe(
    // filter(m => m !== null),
    switchMap(method => of(method === PaymentMethod.credit))
  )
  isDebitMethod = this.paymentMethod.pipe(
    // filter(m => m !== null),
    switchMap(method => of(method === PaymentMethod.debit))
  )
  isBankMethod = this.paymentMethod.pipe(
    // filter(m => m !== null),
    switchMap(method => of(method === PaymentMethod.bank))
  )

  @Output() choice = new EventEmitter<PaymentMethod | null>();

  disableSubmit = new BehaviorSubject<boolean>(false);

  constructor(
    public router: Router,
    private paymentMethodService: PaymentMethodCreateService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
  ) {
    this.subscriptions.push(
      this.activatedRoute.data.subscribe(
        data => {
          this.isOnboarding = data.isOnboarding || false
        }
      )
    );
    // if(this.activatedRoute.firstChild) {
      this.subscriptions.push(
        this.activatedRoute.params.subscribe(
          params => {
            params.method && params.method !== 'select' ? this.paymentMethod.next(params.method) : this.paymentMethod.next(null);
          }
        )
      )
    // }
  }

  ngOnInit(): void {
    this.subscriptions.push(
        this.paymentMethod.subscribe(m => this.choice.emit(m))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s ? s.unsubscribe() : null);
  }

  onMethodChosen(event: PaymentMethod) {
    if (this.isOnboarding) {
      this.router.navigate(['/onboarding/payments', event]);
    } else {
      this.paymentMethod.next(event);
    }
  }

  onSuccess() {
    this.success.emit(true);
    if (this.isOnboarding) {
      this.router.navigate(['/onboarding/payments-new']);
    }
  }

  savePaymentMethodClick() {
    if(this.creditCardFormComponent) {
      this.creditCardFormComponent.onSubmit()
    } else if(this.creditCardFormComponentPayrix) {
      this.creditCardFormComponentPayrix.onSubmit()
    }
  }

}
