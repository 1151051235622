import {Injectable} from "@angular/core";
import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import { Router } from "@angular/router";

export const IGNORE_TOASTR_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  ignore = [
    '/users/me/avatar',
    '/users/me',
    '/password/reset',
  ];

  constructor(
    private toastr: ToastrService,
    private router: Router,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errorResponse: HttpErrorResponse, caught) => {
        console.log('errerrerrerr', errorResponse)
        if (errorResponse.status === 493) {
          this.toastr.clear();
          this.toastr.error('You may not modify this information when logged in as a tenant.', 'Error', {closeButton: true, enableHtml: true})
        }

        let inIgnore = false;
        this.ignore.forEach((i) => {
          return inIgnore = errorResponse.url?.endsWith(i) ? true : inIgnore;
        });
        if (errorResponse.url?.startsWith('https://api.openweathermap.org/')) {
          inIgnore = true;
        }

        if (!inIgnore && !req.context.get(IGNORE_TOASTR_ERROR)) {
          const errors = Object.values(errorResponse.error?.errors || {});
          if (errorResponse.status === 422 && errors.length && errors[0] && Array.isArray(errors[0])) {
            // show first error
            this.toastr.error(errors[0][0], 'Validation error', {closeButton: true, enableHtml: true})
          } else if (errorResponse.status === 401) {
            this.toastr.error('The session has expired. Please sign in again');
            this.router.navigate(['/login']);
          } else if (typeof errorResponse.error.message === 'string') {
            this.toastr.error(errorResponse?.error?.message, 'Error', {closeButton: true, enableHtml: true})
          } 
        }

        throw errorResponse;
      })
    );
  }
}
